import { memo, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { differenceInMilliseconds } from 'date-fns';
import useScrollPos from 'hooks/useScrollPos';
import {
  ONEDAY_MILLISECONDS,
  convertMillisecondsToDurationString,
  ONEHOUR_MILLISECONDS,
  calculateDiffTime,
} from 'utils/calculateTime';
import {
  calculateDiffInDays,
  makeHyphenDateStringToSlash,
} from 'utils/calculateDate';
import { getTimerType } from 'utils/shopby/event';
import Typography from 'components/atoms/Typography';
import Icon, { TIconTag } from 'components/atoms/Icon';
import Timer, { TimerProps } from 'components/atoms/Timer';
import NewLazyImage from 'components/atoms/NewLazyImage';
import { StarIcon } from 'static/newIcons/filled';
import { theme } from 'static/styles/theme';
import { ellipsisLinesCss } from 'static/styles/utils';
import productThumbnailSmall from 'static/images/blank/blank_camfit64.png';
import { NewImageContainer } from 'widgets/ImageContainer';
import { checkPopPath } from 'store/reducers/search';
import useExposeFeedbackLog from 'hooks/FeedbackLog/useExposeFeedbackLog';

interface Props {
  itemNo: number;
  itemName: string;
  brandName: string;
  thumbnailUrl: string;
  salePrice: number;
  discountPercent: number | undefined;
  isSwiper?: boolean;
  numOfColumn?: number;
  className?: string;
  tags: TIconTag[];
  immediateDiscountStartYmdt: string | undefined;
  immediateDiscountEndYmdt: string | undefined;
  immediateDiscountAmt: number;
  isSoldOut: boolean;
  promotionText: string;
  reviewRating: number;
  totalReviewCount: number;
  forGTMClassName?: string;
  isSecretPrice?: boolean;

  onClickFuncForFeedbackLog?: () => void;
  onExposeBulkFeedbackLog?: () => void;
}
enum ProgressType {
  /**
   * 피그마기준 : 오픈예정
   */
  scheduled = '예정',
  /**
   * 피그마기준 : 오픈예정 24시간전
   */
  openImminent = '오픈임박',
  /**
   * 피그마기준 : 진행중
   */
  ongoing = '진행',
  /**
   * 피그마기준 : 마감 6시간전
   */
  closeImminent = '마감임박',
  /**
   * 피그마기준 : 특가종료
   */
  end = '종료',
  /**
   * 커머스팀 요청사항으로 인한 특가 미표기 상태값 추가.
   */
  dismiss = '미표기',
}

export type TProgressStandard = keyof typeof ProgressType;

const ShopbyItemCell = ({
  itemNo,
  itemName,
  brandName,
  thumbnailUrl,
  salePrice,
  discountPercent,
  isSwiper,
  numOfColumn = 2,
  className,
  tags,
  immediateDiscountStartYmdt,
  immediateDiscountEndYmdt,
  immediateDiscountAmt,
  isSoldOut,
  promotionText,
  reviewRating,
  totalReviewCount,
  forGTMClassName,
  isSecretPrice,
  onClickFuncForFeedbackLog,
  onExposeBulkFeedbackLog,
}: Props) => {
  const dispatch = useDispatch();
  const { savePos, pathname } = useScrollPos();

  const discountStartDate = immediateDiscountStartYmdt
    ? new Date(
        makeHyphenDateStringToSlash(immediateDiscountStartYmdt),
      ).getTime()
    : 0;
  const discountEndDate = immediateDiscountEndYmdt
    ? new Date(makeHyphenDateStringToSlash(immediateDiscountEndYmdt)).getTime()
    : 0;

  const [currentTimerType, setCurrentTimerType] = useState<
    TProgressStandard | 'regularSale' | 'soldOut'
  >('regularSale');

  const [currentTimerProps, setCurrentTimerProps] = useState<
    undefined | TimerProps
  >(undefined);

  useEffect(() => {
    if (isSoldOut) {
      setCurrentTimerType('soldOut');
      setCurrentTimerProps(() =>
        getSpecialPricePropsByType(
          'soldOut',
          discountStartDate,
          discountEndDate,
          commonProps,
        ),
      );
      return;
    }

    const timerType = getSpecialPriceType();

    if (
      timerType !== 'dismiss' &&
      timerType !== 'regularSale' &&
      checkIsTimerShow(timerType)
    ) {
      const timerProps = getSpecialPricePropsByType(
        timerType,
        discountStartDate,
        discountEndDate,
        commonProps,
      );
      setCurrentTimerType(timerType);
      setCurrentTimerProps(timerProps);
    }
  }, [itemNo, immediateDiscountStartYmdt, immediateDiscountEndYmdt]);

  const checkIsTimerShow = (timerType: TProgressStandard) => {
    let isTimerShow = true;
    let diffTimes;
    switch (timerType) {
      case 'ongoing':
        diffTimes =
          immediateDiscountEndYmdt &&
          calculateDiffTime(
            new Date(),
            new Date(makeHyphenDateStringToSlash(immediateDiscountEndYmdt)),
          );
        if (diffTimes && diffTimes > ONEDAY_MILLISECONDS * 7) {
          // 타임특가의 의미를 강조하기위해 남은 기간이 7일 이상일 시에는 미표기한다.
          isTimerShow = false;
        }
        break;
      case 'scheduled':
        diffTimes =
          immediateDiscountStartYmdt &&
          calculateDiffTime(
            new Date(),
            new Date(makeHyphenDateStringToSlash(immediateDiscountStartYmdt)),
          );

        if (diffTimes && diffTimes > ONEDAY_MILLISECONDS * 7) {
          // 타임특가의 의미를 강조하기위해 남은 기간이 7일 이상일 시에는 미표기한다.
          isTimerShow = false;
        }
        break;
      default:
        break;
    }
    return isTimerShow;
  };

  const getSpecialPriceType = (): TProgressStandard | 'regularSale' => {
    if (!immediateDiscountStartYmdt || !immediateDiscountEndYmdt) {
      return 'regularSale';
    }

    return getTimerType(discountStartDate, discountEndDate);
  };

  const onUpdateTimer = () => {
    const timerType = getSpecialPriceType();
    if (timerType !== 'regularSale') {
      const timerProps = getSpecialPricePropsByType(
        timerType,
        discountStartDate,
        discountEndDate,
        commonProps,
      );
      setCurrentTimerType(timerType);
      setCurrentTimerProps(timerProps);
    }
  };

  const saveScrollPos = () => {
    savePos();
    // NOTE: popPath는 스크롤이 필요한 목록 내 아이템을 클릭했을 때 저장하는 데이터. popPath가 있을 시, 새로고침이 아닌 뒤로가기로 진입한 케이스임.
    dispatch(checkPopPath(pathname));
  };

  const commonProps = {
    onUpdateTimerStatus: onUpdateTimer,
  };

  const getClassName = (classNames: (string | undefined)[]) => {
    return classNames.filter(className => className !== undefined).join(' ');
  };

  const cellRef = useRef<HTMLAnchorElement>(null);

  useExposeFeedbackLog({
    targetRef: cellRef,
    onIntersect: onExposeBulkFeedbackLog,
  });

  return (
    <ItemCell
      ref={cellRef}
      to={{
        pathname: `/products/items/${itemNo}`,
        state: { from: pathname },
      }}
      $isSwiper={!!isSwiper}
      $numOfColumn={numOfColumn}
      onClick={() => {
        saveScrollPos();
        onClickFuncForFeedbackLog && onClickFuncForFeedbackLog();
      }}
      className={getClassName([className, forGTMClassName])}
    >
      <NewImageContainer ratio={100} containerZIndex={450} imgZIndex={900}>
        <div
          className={
            currentTimerType === 'end' || currentTimerType === 'soldOut'
              ? 'grey-dim'
              : undefined
          }
        />
        <NewLazyImage
          src={thumbnailUrl}
          placeholderImage={productThumbnailSmall}
          alt={itemName}
        />
        {currentTimerType !== 'regularSale' && currentTimerProps && (
          <Timer {...currentTimerProps} cssObject={basicTimerCss} />
        )}
      </NewImageContainer>
      <ProductTextInfo $numOfColumn={numOfColumn}>
        <Typography
          weight="medium"
          variant="caption1"
          color={currentTimerType === 'soldOut' ? 'grey1' : undefined}
        >
          {brandName}
        </Typography>
        <Typography
          weight="regular"
          variant="footnote"
          color={currentTimerType === 'soldOut' ? 'grey1' : undefined}
          cssObject={css`
            ${ellipsisLinesCss}
            -webkit-line-clamp: 2;
          `}
        >
          <span dangerouslySetInnerHTML={{ __html: itemName }} />
        </Typography>

        {promotionText && (
          <Typography
            weight="regular"
            variant="footnote"
            color="acssentOrangePrimary"
            cssObject={css`
              ${ellipsisLinesCss}
            `}
          >
            {promotionText}
          </Typography>
        )}

        {isSecretPrice ? (
          <Typography
            weight="bold"
            variant={numOfColumn === 2 ? 'number15' : 'number13'}
            color={currentTimerType === 'soldOut' ? 'grey1' : undefined}
          >
            시크릿 최저가
          </Typography>
        ) : discountPercent ? (
          <span>
            <Typography
              weight="bold"
              variant={numOfColumn === 2 ? 'number15' : 'number13'}
              color={
                currentTimerType === 'soldOut'
                  ? 'grey1'
                  : 'acssentOrangePrimary'
              }
            >
              {discountPercent}%
            </Typography>
            <Typography
              weight="bold"
              variant={numOfColumn === 2 ? 'number15' : 'number13'}
              color={currentTimerType === 'soldOut' ? 'grey1' : undefined}
            >
              {(salePrice - immediateDiscountAmt).toLocaleString()}원
            </Typography>
          </span>
        ) : (
          <Typography
            weight="bold"
            variant={numOfColumn === 2 ? 'number15' : 'number13'}
            color={currentTimerType === 'soldOut' ? 'grey1' : undefined}
          >
            {salePrice.toLocaleString()}원
          </Typography>
        )}
      </ProductTextInfo>
      <IconBox>
        {tags.map((tag, index) =>
          // 아이콘은 여러개 있더라도 레이아웃상 최대 2개만 보여준다.
          index < 2 ? (
            <Icon
              key={index}
              text={tag}
              inActive={currentTimerType === 'soldOut'}
            />
          ) : (
            <></>
          ),
        )}
      </IconBox>
      <ReviewRateWrapper>
        {totalReviewCount > 0 && (
          <>
            <StarIcon
              width={14}
              height={14}
              fill={theme.newColors.acssentOrangePrimary}
            />
            <Typography weight="bold" variant="caption1">
              {reviewRating.toFixed(1)}
            </Typography>
            <Typography weight="bold" variant="caption1" color="grey1">
              ({totalReviewCount})
            </Typography>
          </>
        )}
      </ReviewRateWrapper>
    </ItemCell>
  );
};

export default memo(ShopbyItemCell);

interface Style {
  $isSwiper?: boolean;
  $numOfColumn?: number;
}

const ItemCell = styled(Link)<Style>`
  width: ${({ $isSwiper, $numOfColumn }) =>
    $isSwiper
      ? ''
      : $numOfColumn === 3
      ? 'calc(33.33% - 5.4px)'
      : 'calc(50% - 5.5px)'};

  img {
    border-radius: 8px;
    border: ${({ theme }) => `1px solid ${theme.newColors.lightGrey1}`};
  }

  .grey-dim {
    border-radius: 8px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 990;
    background: ${({ theme }) => theme.newColors.lightGrey1};
    opacity: 0.5;
  }
`;

const ProductTextInfo = styled.div<Style>`
  margin-top: ${({ $numOfColumn }) => ($numOfColumn === 2 ? '10px' : '8px')};
  margin-bottom: 4px;

  & > :nth-child(2) {
    margin-bottom: 4px;
  }

  span {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    p:first-of-type {
      margin: 0 2px 0 0;
    }
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
`;

const ReviewRateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 2px;
`;

export const timerOpenImminentCss = css`
  background: ${({ theme }) => theme.newColors.orange40};
`;

export const timerCloseImminentCss = css`
  @keyframes colorChange {
    0% {
      background: #f85900;
    }
    100% {
      background: #f87600;
    }
  }
  will-change: background;
  animation: 0.4s ease-out infinite alternate colorChange;
`;

export const basicTimerCss = css`
  position: absolute;
  z-index: 999;
  left: 4px;
  bottom: 4px;
  width: calc(100% - 8px);
  &.open-imminent {
    ${timerOpenImminentCss}
  }
  &.close-imminent {
    ${timerCloseImminentCss}
  }
`;

const getSpecialPricePropsByType = (
  type: TProgressStandard | 'soldOut',
  discountStartDate: number,
  discountEndDate: number,
  commonProps: Pick<TimerProps, 'onUpdateTimerStatus'>,
): TimerProps => {
  switch (type) {
    case 'scheduled':
      return {
        ...commonProps,
        textStyle: 'timerText',
        text: '오픈예정',
        backgroundColor: theme.newColors.darkGrey2,
        imminentStandardMilliSeconds: ONEDAY_MILLISECONDS,
        targetEndTimeMilliseconds: discountStartDate,
        getTimeCustomedFormat: targetEndMillis => {
          const now = new Date();
          const dayDiffs = calculateDiffInDays(targetEndMillis, now);
          const leftTimes = differenceInMilliseconds(
            now.getTime() + dayDiffs * ONEDAY_MILLISECONDS,
            targetEndMillis,
          );

          if (dayDiffs > 0 && leftTimes > 0) {
            const { hours, minutes, seconds } =
              convertMillisecondsToDurationString(leftTimes);

            return `${dayDiffs}일 ${hours}:${minutes}:${seconds}`;
          }
          return '';
        },
      } as TimerProps;
    case 'openImminent':
      return {
        ...commonProps,
        textStyle: 'textTimer',
        text: '오픈임박',
        backgroundColor: theme.newColors.orange40,
        targetEndTimeMilliseconds: discountStartDate,
        className: 'open-imminent',
      } as TimerProps;
    case 'ongoing':
      return {
        ...commonProps,
        textStyle: 'timerText',
        text: '남음',
        imminentStandardMilliSeconds: 6 * ONEHOUR_MILLISECONDS,
        backgroundColor: theme.newColors.darkGrey2,
        targetEndTimeMilliseconds: discountEndDate,
        getTimeCustomedFormat: targetEndMillis => {
          const now = new Date().getTime(); // now를 밀리초로
          const targetEndTime = new Date(targetEndMillis).getTime(); // targetEndMillis를 밀리초로
          const leftTimes = targetEndTime - now; // 두 밀리초 간의 차이를 계산

          if (leftTimes > 0) {
            const dayDiffs = Math.floor(leftTimes / ONEDAY_MILLISECONDS); // 날짜 차이를 구함
            const remainingMillis = leftTimes % ONEDAY_MILLISECONDS; // 남은 시간 차이 구하기
            const { hours, minutes, seconds } =
              convertMillisecondsToDurationString(remainingMillis);

            return `${dayDiffs}일 ${hours}:${minutes}:${seconds}`;
          }
          return '';
        },
      } as TimerProps;
    case 'closeImminent':
      return {
        ...commonProps,
        textStyle: 'textTimer',
        text: '마감임박',
        backgroundColor: theme.newColors.orange40,
        targetEndTimeMilliseconds: discountEndDate,
        className: 'close-imminent',
      } as TimerProps;
    case 'end':
      return {
        ...commonProps,
        text: '특가종료',
        isTimerInvisible: true,
        backgroundColor: theme.newColors.grey2,
        targetEndTimeMilliseconds: 0,
      } as TimerProps;
    default:
      return {
        ...commonProps,
        isTimerInvisible: true,
        text: '품절',
        backgroundColor: theme.newColors.grey2,
        targetEndTimeMilliseconds: 0,
      } as TimerProps;
  }
};

import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  select,
  take,
  call,
  put,
  actionChannel,
  ActionPattern,
} from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import {
  startFetchingZone,
  getZoneRequest,
  getZoneSuccess,
  getZoneFailure,
  getZonesCouponsRequest,
  fetchZoneInfoRequest,
} from 'store/reducers/zone';
import site, {
  getSiteCntFailure,
  getSiteCntRequest,
  getSiteCntSuccess,
  getSitesRequest,
  getSitesSuccess,
  getSitesFailure,
  getLongTermSitesSuccess,
  getLongTermSiteCntSuccess,
  toggleSiteRequest,
} from 'store/reducers/site';
import {
  IGetCampZoneFilterRequestPayload,
  PeopleCnt,
  ReservationDate,
} from 'store/types';
import {
  getZoneDetail,
  getServicesByZone,
  countSitesByZone,
  findSitesByZone,
} from 'api';
import { getLongTermSites } from 'api/longTerm';
import { INewSite } from '@types';
import {
  BookingType,
  setBookingType,
  setPrevLongStayPeriod,
} from 'store/reducers/longStay';
import { toast } from 'react-toastify';
import makePeriodChangeToastMessage from 'utils/makePeriodChangeToastMessage';
import { fetchApi, createFetchAction } from './createFetchAction';

export function* getZone(params: IGetCampZoneFilterRequestPayload) {
  const bookingType: BookingType = yield select(
    (state: RootState) => state.longStayReducer.bookingType,
  );

  const peopleCnt: PeopleCnt = yield select(
    (state: RootState) => state.reservationReducer.peopleCnt,
  );

  // 장박용
  if (bookingType === 'longStay') {
    const longTermAdultCnt: number = yield select(
      (state: RootState) => state.longStayReducer.adultCnt,
    );
    const longTermTeenCnt: number = yield select(
      (state: RootState) => state.longStayReducer.teenCnt,
    );
    const longTermChildCnt: number = yield select(
      (state: RootState) => state.longStayReducer.childCnt,
    );
    const longTermStartDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.startDate,
    );
    const longTermEndDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.endDate,
    );

    yield put(getZoneRequest());
    yield call<any>(
      fetchApi,
      getZoneDetail,
      getZoneRequest.type,
      getZoneSuccess,
      getZoneFailure,
      {
        id: params.id,
        adult: longTermAdultCnt,
        teen: longTermTeenCnt,
        child: longTermChildCnt,
        startTimestamp: longTermStartDate?.getTime() || 0,
        endTimestamp: longTermEndDate?.getTime() || 0,
      },
    );
  } else {
    const peopleCnt: PeopleCnt = yield select(
      (state: RootState) => state.reservationReducer.peopleCnt,
    );
    const date: ReservationDate = yield select(
      (state: RootState) => state.reservationReducer.date,
    );
    const { adultCnt, teenCnt, childCnt } = peopleCnt;
    const { start, end } = date;

    yield put(getZoneRequest());
    yield call<any>(
      fetchApi,
      getZoneDetail,
      getZoneRequest.type,
      getZoneSuccess,
      getZoneFailure,
      {
        id: params.id,
        adult: adultCnt,
        teen: teenCnt,
        child: childCnt,
        startTimestamp: start ? start.getTime() : 0,
        endTimestamp: end ? end.getTime() : 0,
      },
    );
  }
}

export function* getSites(params: IGetCampZoneFilterRequestPayload) {
  const bookingType: BookingType = yield select(
    (state: RootState) => state.longStayReducer.bookingType,
  );

  const peopleCnt: PeopleCnt = yield select(
    (state: RootState) => state.reservationReducer.peopleCnt,
  );
  const { adultCnt, teenCnt, childCnt } = peopleCnt;
  const date: ReservationDate = yield select(
    (state: RootState) => state.reservationReducer.date,
  );

  const longstartTimestamp = Number(params?.longstartTimestamp) || 0;
  const longendTimestamp = Number(params?.longendTimestamp) || 0;

  if (longstartTimestamp && longendTimestamp) {
    delete params.longstartTimestamp;
    delete params.longendTimestamp;
  }

  // 일반 예약 시
  if (bookingType === 'normal') {
    const date: ReservationDate = yield select(
      (state: RootState) => state.reservationReducer.date,
    );

    const { start, end } = date;

    const { price } = yield select(
      (state: RootState) => state.zoneReducer.zone,
    );

    if (start && end) {
      const requestPayload = {
        ...params,
        adult: adultCnt,
        teen: teenCnt,
        child: childCnt,
        startTimestamp: start ? start.getTime() : 0,
        endTimestamp: end ? end.getTime() : 0,
      };

      yield put(getSitesRequest(requestPayload));
      yield call<any>(
        fetchApi,
        findSitesByZone,
        getSitesRequest.type,
        getSitesSuccess,
        getSitesFailure,
        requestPayload,
      );

      yield put(
        getZonesCouponsRequest({
          zoneId: params.id,
          startTimestamp: start.getTime(),
          endTimestamp: end.getTime(),
          price,
        }),
      );
    }
  }

  // 장박 예약 시
  else if (bookingType === 'longStay') {
    const startDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.startDate,
    );

    const endDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.endDate,
    );

    const prevStartDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.prevStartDate,
    );

    const prevEndDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.prevEndDate,
    );

    const response: {
      status: 'success' | 'fail';
      data: INewSite[];
      unavailableReasonInfo?: any;
    } = yield call(getLongTermSites, params.id, {
      ...params,
      startTimestamp: startDate?.getTime() || 0,
      endTimestamp: endDate?.getTime() || 0,
    });

    yield put(getLongTermSiteCntSuccess(response.data.length));
    yield put(getLongTermSitesSuccess(response));

    if (prevStartDate && prevEndDate && startDate && endDate) {
      if (
        prevStartDate.getTime() !== startDate.getTime() ||
        prevEndDate.getTime() !== endDate.getTime()
      ) {
        const msg = makePeriodChangeToastMessage({
          startDate,
          endDate,
        });

        toast.success(msg);
      }
    }
    yield put(
      setPrevLongStayPeriod({
        startDate,
        endDate,
      }),
    );
  }
}

function* fetchZone() {
  const subChannel: ActionPattern = yield actionChannel(startFetchingZone.type);

  while (true) {
    const action: PayloadAction<IGetCampZoneFilterRequestPayload> = yield take(
      subChannel,
    );
    const zoneId = action.payload.id;
    const bookingType = action.payload.bookingType;

    if (bookingType) {
      yield put(setBookingType(bookingType));
    }

    yield call(getZone, action.payload);

    if (!bookingType || bookingType === 'normal') {
      yield call<any>(
        fetchApi,
        countSitesByZone,
        getSiteCntRequest.type,
        getSiteCntSuccess,
        getSiteCntFailure,
        zoneId,
      );
    }

    yield call(getSites, action.payload);
  }
}

function* fetchZoneInfo() {
  const subChannel: ActionPattern = yield actionChannel(
    fetchZoneInfoRequest.type,
  );
  while (true) {
    const action: PayloadAction<string> = yield take(subChannel);
    const id = action.payload;

    const peopleCnt: PeopleCnt = yield select(
      (state: RootState) => state.reservationReducer.peopleCnt,
    );
    const date: ReservationDate = yield select(
      (state: RootState) => state.reservationReducer.date,
    );
    const { adultCnt, teenCnt, childCnt } = peopleCnt;
    const { start, end } = date;

    yield call<any>(
      fetchApi,
      getZoneDetail,
      getZoneRequest.type,
      getZoneSuccess,
      getZoneFailure,
      {
        id,
        adult: adultCnt,
        teen: teenCnt,
        child: childCnt,
        startTimestamp: start ? start.getTime() : 0,
        endTimestamp: end ? end.getTime() : 0,
      },
    );
  }
}

export function* fetchZoneDetailSaga() {
  yield all([
    fetchZone(),
    fetchZoneInfo(),
    // toggleSites(),  // TODO. params 전달 추가
  ]);
}

import {
  IFeedbackLogBasicParams,
  IFeedbackLogRequest,
} from 'types/feedbackLogs';

/**
 * 현재 페이지의 유형, issuedPageUrl로는 유형 분리하기가 까다롭기에 적당한 페이지의 카테고리를 나누고 지정함.
 * 아래 링크의 쓰레드 참조할것.
 * {@link https://nexteditionworkspace.slack.com/archives/C06KM2981MG/p1719978751367129}
 *
 */
export const getPage = (pathname: string) => {
  switch (true) {
    // 홈
    case /^\/$/.test(pathname):
      return 'home';

    // 캠핑장 검색탭
    case /^\/search$/.test(pathname):
      return 'campSearch';

    // 캠핑장 검색 결과
    case /^\/search\/result$/.test(pathname):
      return 'campSearchResult';

    // 캠핑장 지도 검색 결과
    case /^\/search\/geo$/.test(pathname):
      return 'campSearchGeo';

    // 컬렉션
    case /^\/collection\/\w+$/.test(pathname):
      return 'collection';

    // 기획전
    case /^\/exhibition\/\w+$/.test(pathname):
      return 'exhibition';

    // 예약 기획전
    case /^\/camp\/exhibition$/.test(pathname):
      return 'campExhibition';

    // 캠핑장 상세
    case /^\/camp\/\w+$/.test(pathname):
      return 'camp';

    // 캠핑존 상세
    case /^\/camp\/\w+\/\w+$/.test(pathname):
      return 'zone';

    // 스토어 홈
    case /^\/product$/.test(pathname):
      return 'storeHome';

    case /^\/product\/\w+$/.test(pathname):
      return 'productCategory';

    // 스토어 검색 결과
    case /^\/products$/.test(pathname):
      return 'storeSearchResult';

    // 상품 상세
    case /^\/products\/\w+$/.test(pathname):
      return 'productDetail';

    // 아이템 상세
    case /^\/products\/items\/\w+$/.test(pathname):
      return 'itemDetail';

    // 스토어 기획전
    case /^\/store\/exhibition$/.test(pathname):
      return 'storeExhibition';

    // 캠핑생활 피드
    case /^\/feed$/.test(pathname):
      return 'feed';

    case /^\/feed\/\w+$/.test(pathname):
      return 'feedDetail';

    // 랜딩페이지
    case /^\/landing\/\w+$/.test(pathname):
      return 'landing';

    // 마이페이지
    case /^\/mypage$/.test(pathname):
      return 'myPage';

    // 내 찜한 캠핑장
    case /^\/mypage\/bookmark$/.test(pathname):
      return 'myBookmark';

    // 스토어 큐레이션
    case /^\/curation(\?.*)?$/.test(pathname):
      return 'storeCuration';

    // 유저 프로필
    case /^\/users\/.+$/.test(pathname):
      return 'userProfile';

    default:
      return pathname;
  }
};

export const getMobileOS = () => {
  const userAgent = navigator.userAgent;

  // Android
  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }

  return 'unknown';
};

export const getLaunchTime = () => {
  /** 앱 실행 시점, 해당 피드백이 앱 실행한지 얼마 이후에 발생한건지를 쉽게 확인하기 위함 */
  let launchTime = sessionStorage.getItem('launchTime');

  if (!launchTime) {
    launchTime = `${new Date()}`;
    sessionStorage.setItem('launchTime', launchTime);
  }

  return launchTime;
};

export const makeFeedbackLogObject = (
  params: IFeedbackLogBasicParams,
): IFeedbackLogRequest | undefined => {
  const sessionTrack = sessionStorage.getItem('track');
  if (!sessionTrack) return;

  const jsonTrack = JSON.parse(sessionTrack);
  const currentTimestamp = new Date().getTime();
  const launchTime = getLaunchTime();

  const {
    action,
    actionParam,
    target,
    targetId,
    targetParam,
    visitedPages,
    section,
    sectionId,
    sectionParam,
    pageId,
  } = params;

  const prevPageIndex = visitedPages.length - 2;
  let refererUrl = '';

  if (prevPageIndex >= 0) {
    refererUrl = visitedPages[prevPageIndex];
  }

  return {
    trackId: jsonTrack.trackId,
    trackTime: jsonTrack.trackTime,
    launchTime,
    timestamp: currentTimestamp,
    action,
    actionParam,
    target,
    targetId,
    targetParam,
    page: getPage(window.location.pathname),
    pageUrl: window.location.href,
    referer: getPage(refererUrl),
    refererUrl,
    section,
    sectionId,
    sectionParam,
    pageId,
  };
};

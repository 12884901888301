import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IFeedbackLogBasicParams,
  IFeedbackLogRequest,
} from 'types/feedbackLogs';
import { makeFeedbackLogObject } from 'utils/feedbackLogUtils';

interface Props {
  visitedPages: string[];

  bulkFeedbackLogList: IFeedbackLogRequest[];
}

const initialState: Props = {
  visitedPages: [],

  bulkFeedbackLogList: [],
};

export const feedbackLogSlice = createSlice({
  name: 'feedbackLogs',
  initialState,
  reducers: {
    pushVisitedPages: (state, action: PayloadAction<string>) => {
      state.visitedPages.push(action.payload);
    },

    addBulkFeedbackLog: (
      state,
      action: PayloadAction<IFeedbackLogBasicParams>,
    ) => {
      const convertedFeedbackLog = makeFeedbackLogObject(action.payload);

      if (!convertedFeedbackLog) {
        return;
      }

      state.bulkFeedbackLogList.push(convertedFeedbackLog);
    },

    resetBulkFeedbackLog: state => {
      state.bulkFeedbackLogList = [];
    },

    setNeedToSendBulkFeedbackLog: state => {},
  },
});

export const {
  pushVisitedPages,
  addBulkFeedbackLog,
  resetBulkFeedbackLog,
  setNeedToSendBulkFeedbackLog,
} = feedbackLogSlice.actions;

export default feedbackLogSlice.reducer;

import { IFeedbackLogRequest, ITrackIdRequest } from 'types/feedbackLogs';
import { feedbackLogInstance } from './instance';

export const getTrackId = async (body: ITrackIdRequest) => {
  const res = await feedbackLogInstance.post<{ trackId: string }>(
    `/camfit-insight/generate-track-id`,
    body,
  );

  return res.data.trackId;
};

export const createFeedbackLog = async (body: IFeedbackLogRequest) => {
  const res = await feedbackLogInstance.post<{ trackId: string }>(
    `/camfit-insight/feedback-log`,
    body,
  );
  return res.data.trackId;
};

export const createBulkFeedbackLogs = async (body: IFeedbackLogRequest[]) => {
  const res = await feedbackLogInstance.post<void>(
    `/camfit-insight/feedback-log/bulk`,
    body,
  );

  return res.data;
};

import { useEffect, useRef, useState } from 'react';

type RefType<T> = React.RefObject<T> | React.MutableRefObject<(T | null)[]>;

interface Props<T extends Element> {
  targetRef: RefType<T>;
  onIntersect?: (index?: number) => void;
  options?: IntersectionObserverInit;
  visibleDuration?: number;
}

const useExposeFeedbackLog = <T extends Element>({
  targetRef,
  onIntersect,
  options = { threshold: 0.6 },
  visibleDuration = 500,
}: Props<T>) => {
  const [isInitializedSwipe, setIsInitializedSwipe] = useState(false);
  const timerRefs = useRef<Map<Element, ReturnType<typeof setTimeout>>>(
    new Map(),
  );
  const isIntersectingRefs = useRef<Map<Element, boolean>>(new Map());

  const loggedElements = useRef<Set<Element>>(new Set());

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitializedSwipe(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!onIntersect || !isInitializedSwipe) {
      return;
    }

    const observerCallback: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        const element = entry.target;

        if (entry.isIntersecting) {
          if (loggedElements.current.has(element)) {
            return;
          }

          isIntersectingRefs.current.set(element, true);

          // 이미 타이머가 없을 때만 새로운 타이머 시작
          if (!timerRefs.current.has(element)) {
            const timer = setTimeout(() => {
              if (isIntersectingRefs.current.get(element)) {
                // 배열인 경우 index 찾기
                if (
                  Array.isArray(
                    (targetRef as React.MutableRefObject<(T | null)[]>).current,
                  )
                ) {
                  const elements = (
                    targetRef as React.MutableRefObject<(T | null)[]>
                  ).current;
                  const index = elements.findIndex(ref => ref === element);
                  onIntersect(index);
                  // 로깅 후 Set에 추가
                  loggedElements.current.add(element);
                } else {
                  onIntersect();
                  // 로깅 후 Set에 추가
                  loggedElements.current.add(element);
                }
              }
            }, visibleDuration);

            timerRefs.current.set(element, timer);
          }
        } else {
          // 노출 해제 시 상태 초기화
          isIntersectingRefs.current.set(element, false);

          const existingTimer = timerRefs.current.get(element);

          if (existingTimer) {
            clearTimeout(existingTimer);
            timerRefs.current.delete(element);
          }

          // viewport에서 완전히 벗어났을 때 logged 상태 초기화
          loggedElements.current.delete(element);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    // targetRef가 배열인 경우
    if (
      Array.isArray((targetRef as React.MutableRefObject<(T | null)[]>).current)
    ) {
      const elements = (targetRef as React.MutableRefObject<(T | null)[]>)
        .current;
      elements.forEach(element => {
        if (element) {
          observer.observe(element);
        }
      });
    }
    // 단일 ref인 경우
    else {
      const element = (targetRef as React.RefObject<T>).current;
      if (element) {
        observer.observe(element);
      }
    }

    return () => {
      // 모든 타이머 정리
      timerRefs.current.forEach(timer => clearTimeout(timer));
      timerRefs.current.clear();

      observer.disconnect();
    };
  }, [targetRef, onIntersect, options, visibleDuration, isInitializedSwipe]);
};

export default useExposeFeedbackLog;

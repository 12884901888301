/* eslint-disable no-param-reassign */

import { postJwtError } from 'api';
import { getDeviceId } from './getDeviceId';
import { getCookie, setCookieWithExpiry } from './cookie';

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // 원하는 형식으로 조합
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};

const jwtErrorSlack = async (delay: number) => {
  try {
    const DAY = 24 * 60;
    const now = new Date().getTime();
    const errorCnt = getCookie('camfit_errorCnt')();

    if (window.isJwtThrottling) return;
    window.isJwtThrottling = true;

    // 한번도 발생하지 않은 경우.
    if (!errorCnt) {
      setCookieWithExpiry('camfit_timestamp', now.toString(), DAY);
      setCookieWithExpiry('camfit_errorCnt', '1', DAY);

      setTimeout(() => {
        window.isJwtThrottling = false;
      }, delay);
      return;
    }

    const day = 24 * 60 * 60 * 1000;
    const prevTimestamp = Number(getCookie('camfit_timestamp')());
    const diff = now - prevTimestamp;

    // 발생한지 하루가 지난 경우 초기화.
    if (diff > day) {
      setCookieWithExpiry('camfit_timestamp', now.toString(), DAY);
      setCookieWithExpiry('camfit_errorCnt', '1', DAY);

      setTimeout(() => {
        window.isJwtThrottling = false;
      }, delay);
      return;
    }

    // 발생한지 하루가 지나지 않은 경우.
    const newErrorCnt = Number(errorCnt) + 1;
    const prevDate = formatDate(prevTimestamp);

    const userAgent = navigator.userAgent;
    const isMobile = /Mobile/i.test(userAgent);
    let deviceId = null;

    if (isMobile) {
      deviceId = getDeviceId();
    }

    const values = [];
    for (let i = 0; i < 10; i += 1) {
      const randomKey = Math.round(Math.random() * 10000).toString();
      localStorage.setItem('randomKey', randomKey);
      const getRandomKey = localStorage.getItem('randomKey');
      values.push({
        [randomKey]: getRandomKey,
      });
    }

    setCookieWithExpiry('camfit_timestamp', now.toString(), DAY);
    setCookieWithExpiry('camfit_errorCnt', newErrorCnt.toString(), DAY);

    localStorage.removeItem('randomKey');

    await postJwtError({
      count: newErrorCnt,
      prevDate,
      userAgent,
      deviceId,
      expiredJwt: localStorage.getItem('jwt'),
      values,
    });

    setTimeout(() => {
      window.isJwtThrottling = false;
    }, delay);
  } catch (error) {
    console.log('jwtErrorSlack ERROR : ', error);
  }
};

export default jwtErrorSlack;

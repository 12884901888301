import { Store } from 'redux';
import { setNeedToSendBulkFeedbackLog } from 'store/reducers/feedbackLog';
import { POST_BULK_FEEDBACK_LOG_COUNT } from 'constants/feedbackLog';
import { RootState } from './reducers';

let isDispatching = false;

export const subscribeToStore = (store: Store<RootState>) => {
  store.subscribe(() => {
    const state = store.getState();
    const bulkFeedbackLogList = state.feedbackLogReducer.bulkFeedbackLogList;

    if (
      bulkFeedbackLogList.length > POST_BULK_FEEDBACK_LOG_COUNT &&
      !isDispatching
    ) {
      isDispatching = true;

      store.dispatch(setNeedToSendBulkFeedbackLog());

      isDispatching = false;
    }
  });
};

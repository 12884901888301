import { all, put, select, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { postBulkFeedbackLogs } from 'hooks/useGetTrackId';
import {
  resetBulkFeedbackLog,
  setNeedToSendBulkFeedbackLog,
} from 'store/reducers/feedbackLog';
import { IFeedbackLogRequest } from 'types/feedbackLogs';

function* sendBulkFeedbackLogSaga() {
  while (true) {
    const action: PayloadAction<any> = yield take(
      setNeedToSendBulkFeedbackLog.type,
    );

    const bulkFeedbackLogList: IFeedbackLogRequest[] = yield select(
      (state: RootState) => state.feedbackLogReducer.bulkFeedbackLogList,
    );

    if (bulkFeedbackLogList.length > 0) {
      postBulkFeedbackLogs(bulkFeedbackLogList);
      yield put(resetBulkFeedbackLog());
    }
  }
}

export default function* feedbackLogSaga() {
  yield all([sendBulkFeedbackLogSaga()]);
}

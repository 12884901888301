import { ErrorInfo } from 'react';
import axios from 'axios';
import { getEnvironment } from 'utils/common';
import { getServerURL } from 'server';

const baseApi = axios.create({
  baseURL: getServerURL(),
  headers: { 'Cache-Control': 'no-cache' },
});

export const postSlackNotiFrontendError = async (
  error: Error,
  info: ErrorInfo,
) => {
  const { name, message, stack } = error;
  const { componentStack } = info;

  // componentStackInfo가 너무 길어지는 경우 사용.
  // provider등을 제거하여 출력.
  const trimmedComponentStackInfo = componentStack
    .substring(0, componentStack.indexOf('at Switch'))
    .trim();

  const environment = getEnvironment().toUpperCase();

  const text = `
  🚨 *프론트엔드 : ${environment}* 🚨
  \`\`\`
  path : ${window.location.href}
  name : ${name}
  message : ${message}
  stack : ${stack}

  componentStackInfo : ${componentStack}
  \`\`\`
  `;

  try {
    await baseApi.post('v1/slack', {
      message: text,
    });
  } catch (error) {
    console.log('error', error);
  }
};
